import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cookie"
export default class extends Controller {
  connect() {
  }

  set(event) {
    let cookieString = '';
    for (const [key, value] of Object.entries(event.params['cookie'])) {
      cookieString += `${key}=${value}; `
    }
    document.cookie = cookieString;
  }
}
