import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="analytics"
export default class extends Controller {
  static values = {
    trackingId: String
  };

  connect() {
  }

  startTracking() {
    var scriptTag = document.createElement('script');
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=G-${this.trackingIdValue}`;
    scriptTag.async = 1;
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', `G-${this.trackingIdValue}`);
  }

  stopTracking() {
    window[`ga-disable-G-${this.trackingIdValue}`] = true;
    this.deleteGaCookie('_ga');
    this.deleteGaCookie(`_ga_${this.trackingIdValue}`);
  }

  deleteGaCookie(name) {
    // Google Analytics sets the domain of its cookies to '.vostel.de'. You can change the domain
    // (https://developers.google.com/tag-platform/security/guides/customize-cookies) but Google
    // will add a leading dot to any domain you set.
    //
    // According to MDN, leading dots should be ignored:
    // https://developer.mozilla.org/en-US/docs/Web/API/document/cookie
    // However, at least Firefox does not delete cookies with domain '.vostel.de' if we are on
    // vostel.de and don't specify the domain in the document.cookie invocation.
    document.cookie = `${name}=; expires=Mon Jan 01 1900 00:00:00; path=/; SameSite=Lax; domain=.vostel.de`;
  }
}
