import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="obfuscation"
export default class extends Controller {
  static values = { encoded: String, key: String, protocol: String, setText: Boolean };

  connect() {
    let address = this.decode(this.encodedValue, this.keyValue);
    let protocol = this.rot13LowercaseAlpha(this.protocolValue);
    let result = `${protocol}:${address}`;

    let linkElement = this.scope.element;
    linkElement.setAttribute('href', result);

    if (this.setTextValue) {
      linkElement.innerText = address;
    }
  }

  rot13LowercaseAlpha(message) {
    const originalAlpha = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const cipher = "nopqrstuvwxyzabcdefghijklmNOPQRSTUVWXYZABCDEFGHIJKLM"
    return message.replace(/[a-z]/gi, letter => cipher[originalAlpha.indexOf(letter)])
  }

  decode(encodedStr, keyStr) {
    let key = keyStr.split(',');
    const originalAlphabet = "abcdefghijklmnopqrstuvwxyz0123456789@.+ ()";
    let cipherAlphabet = new Array(originalAlphabet.length);

    for (let i = 0; i < key.length; i++) {
      cipherAlphabet[key[i]] = originalAlphabet[i];
    }

    return encodedStr.replace(/[a-z0-9@+\. \(\)]/gi,
      letter => originalAlphabet[cipherAlphabet.indexOf(letter)]);
  }
}
