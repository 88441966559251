import React from 'react';
import cN from 'classnames';
import { map } from 'lodash';
import s from './style.module';
import Project from 'components/Project';
import closeIcon from '!svg-inline-loader!images/icons/close.svg';
import closeIconGrey from '!svg-inline-loader!images/icons/small-x_grey.svg';
import arrowIcon from '!svg-inline-loader!images/icons/arrow_no_shaft.svg';
import { MOBILE_VIEW_UNTIL_WIDTH } from '../../common/vars';
import { isUserAgentIE } from '../../common/helper';

const MODAL_MODE_NONE = 'none'; // no modal is shown
const MODAL_MODE_LOADING = 'loading' // iframe content is loading
const MODAL_MODE_PREVIEW = 'preview'; // iframe content is loaded, only project title and orga name are shown, not scrollable
const MODAL_MODE_CHANGE_CURRENT = 'change_current' // a project was shown in initial mode, user clicked on next or previous button, the next project is now loading
const MODAL_MODE_EXPANDED = 'expanded'; // iframe content is loaded, whole project view is shown, scrollable
const HIDE_IN_PREVIEW = [ // elements with these ids in the projects#show view are hidden in the preview modal
  'image-header-increment',
  'image-header-badge',
  'image-header-orga-logo'
]

const MARKER_SIZES = {
  simpleMarker: { x: 28, y: 28 },
  singlePositionCluster: { x: 37, y: 37 },
  multiPositionCluster: { x: 45, y: 45 },
}

class ProjectMap extends React.Component {
  constructor(props) {
    super(props);
    this.bindMethodsToThis();
    this.initMap = this.initMap.bind(this);
    this.projectEventHandler = new ProjectEventHandler();
    this.state = {
      projectModalMode: MODAL_MODE_NONE
    };
    this.projectModal = null;
    this.projectIframe = null;
    this.projectModalPreviewEventListener = null;
    this.lastMarkerId = 0;
  }

  bindMethodsToThis() {
    this.markerClicked = this.markerClicked.bind(this);
    this.markerClusterClicked = this.markerClusterClicked.bind(this);
    this.mapBoundsChange = this.mapBoundsChange.bind(this);
    this.closeSelection = this.closeSelection.bind(this);
    this.dragstart = this.dragstart.bind(this);
    this.dragend = this.dragend.bind(this);
    this.projectClicked = this.projectClicked.bind(this);
    this.projectModalClose = this.projectModalClose.bind(this);
    this.projectModalLoaded = this.projectModalLoaded.bind(this);
    this.projectModalClick = this.projectModalClick.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.onWindowScroll = this.onWindowScroll.bind(this);
    this.projectModalTouchstart = this.projectModalTouchstart.bind(this);
    this.projectModalTouchmove = this.projectModalTouchmove.bind(this);
    this.preventDefaultListener = this.preventDefaultListener.bind(this);
    this.markerClusterRenderer = this.markerClusterRenderer.bind(this);
    this.clusteringEnd = this.clusteringEnd.bind(this);
  }

  componentDidMount() {
    if (window.google && window.markerClusterer && window.markerClusterer.MarkerClusterer) {
      this.initMap();
    } else {
      document.addEventListener('googleMapsLoaded', this.initMap);
    }

    this.mqList = window.matchMedia(`(max-width: ${MOBILE_VIEW_UNTIL_WIDTH}px)`)
    if (!isUserAgentIE()) {
      this.mqList.addEventListener("change",
          () => {  
              this.onMobileBreak(this.mqList.matches);
          });
    }
    this.onMobileBreak(this.mqList.matches);
  }

  componentWillUnmount() {
    if (this.state.mobileView) {
      this.enableScrolling();
      window.removeEventListener('resize', this.onWindowResize);
      location.hash = '#project-search'
    }
  }

  scrollToTop() {
    this.scrollingToTop = true;
    window.scrollTo(0, 0);
    this.scrollingToTop = false;
  }

  onWindowResize() {
    if (this.state.mobileView) {
      this.scrollToTop();
    }
  }

  onWindowScroll(event) {
    if (this.state.mobileView) {
      // If mobileView is true, it should not be possible to scroll. There are 2 exceptions to this rule:
      // 1. The current activeElement is the input field for messages. Then the virtual keyboard is open and it should be possible to scroll down.
      // When the input field loses focus (i.e. the virtual keyboard is closed), we should scroll back up.
      // 2. We are explicitly scrolling up via this.scrollToTop. In this case, this.scrollingToTop is set to true.
      let activeElement = this.projectIframe && this.projectIframe.contentDocument ? this.projectIframe.contentDocument.activeElement : null;
      if (activeElement && activeElement.id === 'message_edit_model_body') {
        activeElement.addEventListener('focusout', function() { this.scrollToTop(); }.bind(this));
      } else {
        if (!this.scrollingToTop) {
          event.preventDefault();
        }
      }
    }
  }

  onMobileBreak(mobileView) {
    this.setState({mobileView: mobileView}, () => {
      if (mobileView) {
        this.scrollToTop();
        this.disableScrolling();
      } else {
        this.enableScrolling();
      }
    });
  }

  preventDefaultListener(event) {
    event.preventDefault();
  }

  disableScrolling() {
    document.body.style.overflow = 'hidden';
    window.addEventListener('touchmove', this.preventDefaultListener, {passive: false}); // prevent bounce effect
    window.addEventListener('resize', this.onWindowResize);
    window.addEventListener('scroll', this.onWindowScroll);
  }

  enableScrolling() {
    document.body.style.overflow = 'visible';
    window.removeEventListener('touchmove', this.preventDefaultListener)
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  initMap() {
    this.createMap();
    this.createMapContent();
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(_.map(oldProps.projects, 'id'), _.map(this.props.projects, 'id'))) {
      var content, marker;
      if (this.selectionMatchesProjects()) {
        content = this.infoWindow && this.infoWindow.getContent();
        marker = this.infoWindow ? this.infoWindow.marker : null;
      } else {
        this.closeSelection();
      }
      this.clearMapContent();
      this.createMapContent();
      if (content && marker) {
        this.createInfoWindow(content, marker, true);
      }
    }
  }

  clearMapContent() {
    this.markerClusterer.clearMarkers();
  }

  createMapContent() {
    this.createMarkers();
    this.createMarkerTitleAndIcon();
    this.extendAndFitMap();
    this.createMarkerClusterer();
  }

  createMap() {
    let mapNode = document.getElementById('google-map');
    this.map = new google.maps.Map(mapNode, {styles: vostel.mapStyle, fullscreenControl: false, streetViewControl: false, mapTypeControl: false });
    this.map.addListener('bounds_changed', this.mapBoundsChange);
    this.map.addListener('dragstart', this.dragstart);
    this.map.addListener('dragend', this.dragend);
    if (Array.isArray(this.props.childrenIds)) {
      this.props.childrenIds.forEach((childId) => {
        let child = document.getElementById(childId);
        if (child) {
          mapNode.appendChild(child);
        }
      });
    }
    window.myMap = this.map;
  }

  /**
   * Determines the cluster the given marker is part of. Expects that each marker has a unique proprty {id}.
   * 
   * @param {google.maps.Marker} marker 
   * @returns Returns the cluster marker, {marker} is part of, or null if there is no such cluster marker.
   */
  getClusterForMarker(marker) {
    for (let cluster of this.markerClusterer.clusters) {
      if (cluster.markers.some(m => marker.id === m.id)) {
        return cluster;
      }
    }
    return null;
  }

  /**
   * Places this.infoWindow above this.infoWindow.marker or the cluster marker this.infoWindow.marker belongs to.
   */
  setInfoWindowPosition() {
    if (!this.infoWindow || !this.infoWindow.marker) {
      return;
    }
    
    let visibleMarker = this.infoWindow.marker;
    const clusterForMarker = this.getClusterForMarker(this.infoWindow.marker);
    if (clusterForMarker) {
      visibleMarker = clusterForMarker.marker;
    }
        
    const markerIcon = visibleMarker ? visibleMarker.getIcon() : null;
    const markerHeight = markerIcon && markerIcon.size ? markerIcon.size.height : null;
    const markerPositionY = markerIcon && markerIcon.anchor ? markerIcon.anchor.y : 0;
    const distanceToMarker = 3;
    const defaultYOffset = 32;
    const yOffset = markerHeight ? markerHeight - markerPositionY + distanceToMarker : defaultYOffset;

    const scale = Math.pow(2, this.map.getZoom());
    const centerPoint = this.map.getProjection().fromLatLngToPoint(visibleMarker.getPosition());
    const centerWithOffset = this.map.getProjection().fromPointToLatLng({ x: centerPoint.x, y: centerPoint.y - yOffset / scale});
    
    this.infoWindow.setPosition(centerWithOffset);
  }

  createInfoWindow(content, marker, contentIsHtml = false) {
    if (!this.infoWindow) {
      this.infoWindow = new google.maps.InfoWindow();
      google.maps.event.addListener(this.infoWindow, 'closeclick', this.closeSelection);
      google.maps.event.addListener(this.map, 'click', this.closeSelection);
    }
    if (!contentIsHtml) {
      content = '<div class="' + s['info-window__content'] + '">' + content + '</div>';
    }
    this.infoWindow.setContent(content);
    
    this.infoWindow.marker = marker;
    this.setInfoWindowPosition();
    this.infoWindow.open(this.map);
  }

  repositionInfoWindow() {
    if (!this.infoWindow) {
      return
    }
    if (!this.map.getBounds().contains(this.infoWindow.getPosition())) {
      return;
    }
    this.setInfoWindowPosition();
  }

  createMarker(options) {
    let newMarker = new google.maps.Marker(options);
    newMarker.id = ++this.lastMarkerId;
    return newMarker;
  }

  //

  createMarkers() {
    this.markerIcons = {
      simpleMarker: {
        url: this.mapsIconsPath() + '/simple-marker.svg',
        scaledSize: new google.maps.Size(MARKER_SIZES.simpleMarker.x, MARKER_SIZES.simpleMarker.y)
      },
      singlePositionCluster: {
        url: this.mapsIconsPath() + '/single-position-cluster.svg',
        scaledSize: new google.maps.Size(MARKER_SIZES.singlePositionCluster.x, MARKER_SIZES.singlePositionCluster.y)
      },
      multiPositionCluster: {
        url: this.mapsIconsPath() + '/multi-position-cluster.svg',
        anchor: new google.maps.Point(MARKER_SIZES.multiPositionCluster.x / 2, MARKER_SIZES.multiPositionCluster.y / 2),
        scaledSize: new google.maps.Size(MARKER_SIZES.multiPositionCluster.x, MARKER_SIZES.multiPositionCluster.y)
      }
    }

    const that = this;
    this.markers = [];
    var markerMap = {};
    this.projectMarkers = {};
    for (var i = 0; i < this.props.projects.length; i++) {
      var project = this.props.projects[i];
      var locationKey = '' + project.latitude + '/' + project.longitude;
      if (markerMap[locationKey]) {
        this.projectMarkers[project.id] = markerMap[locationKey];
        markerMap[locationKey].projects.push(project);
        var hiddenMarker = this.createMarker({
          position: {
            lat: project.latitude,
            lng: project.longitude,
          },
          visible: false,
        });
        this.markers.push(hiddenMarker);
      } else {
        var marker = this.createMarker({
          position: {
            lat: project.latitude,
            lng: project.longitude,
          },
        });
        this.projectMarkers[project.id] = marker;
        marker.projects = [project];
        marker.addListener('click', function() {
          that.markerClicked(this);
        });
        this.markers.push(marker);
        markerMap[locationKey] = marker;
      }
    }
  }

  createMarkerTitleAndIcon() {
    for (var i = 0; i < this.markers.length; i++) {
      var marker = this.markers[i];
      if (!marker.getVisible()) {
        continue;
      }
      marker.setTitle(marker.projects[0].title);
      marker.setIcon(this.markerIcons.simpleMarker);
    }
  }

  extendAndFitMap() {
    var bounds = new google.maps.LatLngBounds();
    if (this.props.mapBounds) {
      const mapBoundsParts = this.props.mapBounds.split('/');
      const northWest = new google.maps.LatLng(mapBoundsParts[0], mapBoundsParts[1]);
      const southEast = new google.maps.LatLng(mapBoundsParts[2], mapBoundsParts[3]);
      bounds.extend(northWest);
      bounds.extend(southEast);
      this.map.fitBounds(bounds, -1);
    } else if (this.markers.length) {
      for (var i = 0; i < this.markers.length; i++) {
        var marker = this.markers[i];
        bounds.extend(marker.position);
      }
      this.map.fitBounds(bounds);
    } else if (this.props.latitude && this.props.longitude) {
      this.map.setCenter(new google.maps.LatLng(this.props.latitude, this.props.longitude));
      this.map.setZoom(13);
    } else if (this.props.locationSearch) {
      const request = {
        query: this.props.locationSearch
      };
      const service = new google.maps.places.PlacesService(this.map);
      service.textSearch(request, (function (results, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          this.map.setCenter(results[0].geometry.location);
          this.map.setZoom(13);
        } else {
          const northWestOfGermany = new google.maps.LatLng(55.0846, 5.866944);
          const southEastOfGermany = new google.maps.LatLng(47.271679, 15.043611);
          bounds.extend(northWestOfGermany);
          bounds.extend(southEastOfGermany);
          this.map.fitBounds(bounds);
        }
      }).bind(this));
    } else {
      const northWestOfGermany = new google.maps.LatLng(55.0846, 5.866944);
      const southEastOfGermany = new google.maps.LatLng(47.271679, 15.043611);
      bounds.extend(northWestOfGermany);
      bounds.extend(southEastOfGermany);
      this.map.fitBounds(bounds);
    }
  }

  markerClicked(marker) {
    this.createInfoWindow(marker.getTitle(), marker);
    this.selectProjects(marker.projects);
  }

  createMarkerClusterer() {
    this.markerClusterer = new markerClusterer.MarkerClusterer({
      map: this.map,
      markers: this.markers,
      renderer: { render: this.markerClusterRenderer },
      algorithm: new markerClusterer.SuperClusterAlgorithm({ extent: 256 }),
      onClusterClick: this.markerClusterClicked,
    });

    this.markerClusterer.addListener('clusteringend', this.clusteringEnd);
  }

  clusteringEnd() {
    this.setInfoWindowPosition();
  }

  markerClusterRenderer(cluster, stats) {
    const markerCount = cluster.markers.length; // Don't use cluster.count, as it only returns the number of visible markers.
    const isSinglePosition = this.isSinglePosition(cluster);

    return this.createMarker({
      position: cluster.position,
      label: {
        text: markerCount.toString(),
        color: 'white',
        fontWeight: '800',
        fontFamily: 'Raleway, sans-serif',
        fontSize: '12px',
        className: isSinglePosition ? s['singlePositionCluster'] : null
      },
      icon: isSinglePosition ? this.markerIcons.singlePositionCluster : this.markerIcons.multiPositionCluster,
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + markerCount,
    });
  }

  markerClusterClicked(event, cluster, map) {
    if (this.isSinglePosition(cluster)) {
      var lat = cluster.markers[0].getPosition().lat();
      var lng = cluster.markers[0].getPosition().lng();
      var selectedProjects = [];
      for (var i = 0; i < this.props.projects.length; i++) {
        var project = this.props.projects[i];
        if (this.samePosition(lat, lng, this.projectMarkers[project.id].getPosition().lat(), this.projectMarkers[project.id].getPosition().lng())) {
          selectedProjects.push(project);
        }
      }
      this.createInfoWindow(this.props.multipleProjectsOnLocationLabel, cluster.marker);
      this.selectProjects(selectedProjects);
    } else {
      markerClusterer.defaultOnClusterClickHandler(event, cluster, map);
    }
  }

  isSinglePosition(cluster) {
    var lat, lng;
    var singlePosition = true;
    for (var j = 0; j <  cluster.markers.length; j++) {
      var marker = cluster.markers[j];
      if (lat && lng) {
        if (!this.samePosition(marker.getPosition().lat(), marker.getPosition().lng(), lat, lng)) {
          singlePosition = false;
          break;
        }
      } else {
        lat = marker.getPosition().lat();
        lng = marker.getPosition().lng();
      }
    }
    return singlePosition;
  }

  projectModalClose() {
    this.setState({projectModalMode: MODAL_MODE_NONE});
  }

  setDisplayForElementsToHideInPreview(display) {
    HIDE_IN_PREVIEW.forEach(id => {
      let el = this.projectIframe.contentDocument.getElementById(id);
      if (el) {
        el.style.display = display;
      }
    });
  }

  projectModalLoaded() {
    this.setDisplayForElementsToHideInPreview('none');
    this.setState({projectModalMode: MODAL_MODE_PREVIEW});
    this.projectModalPreviewEventListener.addEventListener('touchstart', this.projectModalTouchstart);
    this.projectModalPreviewEventListener.addEventListener('touchmove', this.projectModalTouchmove);
  }

  projectModalExpand() {
    this.setDisplayForElementsToHideInPreview('');
    this.setState({projectModalMode: MODAL_MODE_EXPANDED});
  }

  ////

  projectModalClick() {
    if (this.state.projectModalMode != MODAL_MODE_EXPANDED) {
      this.projectModalExpand();
    }
  }

  projectModalTouchstart(event) {
    if (event && event.touches && event.touches[0] && event.touches[0].clientY) {
      this.touchstartClientY = event.touches[0].clientY;
    }
  }

  projectModalTouchmove(event) {
    if (event && event.touches && event.touches[0] && event.touches[0].clientY) {
      if (!this.touchstartClientY) {
        this.touchstartClientY = event.touches[0].clientY;
      } else {
        if (this.touchstartClientY - event.touches[0].clientY > 10) {
          this.projectModalSwipeUp();
          this.touchstartClientY = undefined;
        }
      } 
    }
  }

  ///

  projectModalSwipeUp() {
    this.projectModalExpand();
  }

  projectModalChangeCurrentProject(offset) {
    let currentProjectIndex = this.state.currentProjectIndex;
    let newProjectIndex = currentProjectIndex + offset;
    if (newProjectIndex >= 0 && newProjectIndex < this.state.selectedProjects.length) {
      this.setState(
        {
          lastProjectIndex: currentProjectIndex,
          currentProjectIndex: newProjectIndex,
          projectModalMode: MODAL_MODE_CHANGE_CURRENT
        }
      );
    }
  }

  render() {
    const { projects, processTypeLabels, locationIndependentLabel, frequencyLabels, selectionLabel, toProjectLabel } = this.props;
    return (
      <div className="clearfix">
        { this.state.mobileView && this.state.projectModalMode !== MODAL_MODE_NONE && this.state.selectedProjects && (
          <div className={cN(s['modal'], {[s['modal--loading']]: this.state.projectModalMode === MODAL_MODE_LOADING, [s['modal--preview']]: this.state.projectModalMode === MODAL_MODE_PREVIEW || this.state.projectModalMode === MODAL_MODE_CHANGE_CURRENT, [s['modal--expanded']]: this.state.projectModalMode === MODAL_MODE_EXPANDED})}
            ref={el => {this.projectModal = el;}}>
            { this.state.projectModalMode !== MODAL_MODE_EXPANDED && (
              <div className={s['event-listener']} onClick={this.projectModalClick} ref={el => {this.projectModalPreviewEventListener = el;}} />
            ) }         
            <div className={s["close-button"]} onClick={this.projectModalClose} dangerouslySetInnerHTML={{ __html: closeIconGrey }} />
            {
              this.state.selectedProjects.length > 1 &&
                ((this.state.projectModalMode === MODAL_MODE_PREVIEW &&
                  this.state.currentProjectIndex > 0) ||
                  (this.state.projectModalMode === MODAL_MODE_CHANGE_CURRENT &&
                  this.state.lastProjectIndex > 0)) &&
              (
                <div className={cN(`component-project-map__buttons`, s["previous-button"])}
                  onClick={ this.state.projectModalMode === MODAL_MODE_PREVIEW ? () => { this.projectModalChangeCurrentProject(-1) } : null }
                  dangerouslySetInnerHTML={{ __html: arrowIcon }} />
              )
            }
            { 
              this.state.selectedProjects.length > 1 &&
              ((this.state.projectModalMode === MODAL_MODE_PREVIEW &&
                this.state.currentProjectIndex < this.state.selectedProjects.length - 1) ||
                (this.state.projectModalMode === MODAL_MODE_CHANGE_CURRENT &&
                this.state.lastProjectIndex < this.state.selectedProjects.length - 1)) &&
              (
                <div className={cN(`component-project-map__buttons`, s["next-button"])}
                  onClick={ this.state.projectModalMode === MODAL_MODE_PREVIEW ? () => { this.projectModalChangeCurrentProject(+1) } : null }
                  dangerouslySetInnerHTML={{ __html: arrowIcon }} />
              )
            }            
            <iframe className={s["project-iframe"]}
              src={`${this.props.projectsPath}/${this.state.selectedProjects[this.state.currentProjectIndex].id}?inline_view=true`}
              onLoad={this.projectModalLoaded}
              onClick={this.test}
              ref={el => {this.projectIframe = el;}} />
          </div>
          )}
        <div className={s['map-column']}>
          <div className={s.map} id="google-map" />
        </div>
        {
          !this.state.mobileView && (
            <div className={s['list-column']}>
              {this.state.selectedProjects ? (
                <div>
                  <div className="clearfix">
                    <h3 className={cN(`component-project-map__selected-projects-title`, s['selected-projects__title'])}>{selectionLabel}</h3>
                    <span className={cN(`component-project-map__selected-projects-close-button`, s['selected-projects__close-button'])} dangerouslySetInnerHTML={{ __html: closeIcon }} onClick={this.closeSelection} />
                  </div>
                  <ul className={s.list}>
                    {map(this.state.selectedProjects, (project, idx) => (
                      <li className={s.project} key={idx}>
                        <Project {...project} processTypeLabels={processTypeLabels} locationIndependentLabel={locationIndependentLabel} frequencyLabels={frequencyLabels} toProjectLabel={toProjectLabel} expanded={true} advanced={true} />
                    </li>
                    ))}
                  </ul>
                </div>
              ) : (
                this.props.projects.length == 0 ? (
                  this.props.noProjectsFoundLabel
                ) : (
                  <ul className={s.list}>
                    {map(projects, (project, idx) => (
                      <li className={s.project} key={idx}>
                        <Project {...project} processTypeLabels={processTypeLabels} locationIndependentLabel={locationIndependentLabel} frequencyLabels={frequencyLabels} expanded={true} compact={true} onClick={this.projectClicked} />
                    </li>
                    ))}
                  </ul>
                )
              )}
            </div>
          )
        }
      </div>
    );
  }

  mapBoundsChange() {
    if (!this.dragging) {
      const bounds = this.map.getBounds();
      if (!bounds) {
        return;
      }
      const north = bounds.getNorthEast().lat();
      const east = bounds.getNorthEast().lng();
      const south = bounds.getSouthWest().lat();
      const west = bounds.getSouthWest().lng();
      if (this.previousBounds) {
        const northChanged = !this.samePositionDimension(north, this.previousBounds[0]);
        const eastChanged = !this.samePositionDimension(east, this.previousBounds[1]);
        const southChanged = !this.samePositionDimension(south, this.previousBounds[2]);
        const westChanged = !this.samePositionDimension(west, this.previousBounds[3]);
        if (!northChanged && !eastChanged && !southChanged && !westChanged) {
          return;
        }
      }
      this.previousBounds = [north, east, south, west];
      var event = new CustomEvent('mapBoundsChange', { detail: [north, east, south, west] });
      this.props.onMapBoundsChange(event);
      this.repositionInfoWindow();
    }
  }

  dragstart() {
    this.dragging = true;
  }

  dragend() {
    this.dragging = false;
    this.mapBoundsChange();
  }

  projectClicked(id) {
    const projectMarker = this.projectMarkers[id];
    var project;
    for (var i = 0; i < projectMarker.projects.length; i++) {
      if (projectMarker.projects[i].id == id) {
        project = projectMarker.projects[i];
        this.createInfoWindow(project.title, projectMarker);
        this.selectProjects([project]);
        break;
      }
    }
  }

  selectProjects(projects) {
    let state = {selectedProjects: projects};
    if (this.state.mobileView) {
      Object.assign(state, { projectModalMode: MODAL_MODE_LOADING, currentProjectIndex: 0 });
    }
    this.setState(state);
  }

  closeSelection() {
    if (this.infoWindow !== null) {
      google.maps.event.clearInstanceListeners(this.infoWindow);
      this.infoWindow.close();
      this.infoWindow = null;
    }
    this.setState({selectedProjects: null}); 
  }

  selectionMatchesProjects() {
    if (!this.state.selectedProjects) {
      return true;
    }
    const projectIds = _.map(this.props.projects, 'id');
    const selectedProjectIds = _.map(this.state.selectedProjects, 'id');
    for (var i = 0; i < selectedProjectIds.length; i++) {
      if (!_.includes(projectIds, selectedProjectIds[i])) {
        return false;
      }
    }
    const firstSelectedProject = this.state.selectedProjects[0];
    for (var i = 0; i < this.props.projects.length; i++) {
      if (!this.samePosition(firstSelectedProject.latitude, firstSelectedProject.longitude, this.props.projects[i].latitude, this.props.projects[i].longitude)) {
        continue;
      }
      if (!_.includes(selectedProjectIds, this.props.projects[i].id)) {
        return false;
      }
    }
    return true;
  }

  samePosition(lat1, lng1, lat2, lng2) {
    return this.samePositionDimension(lat1, lat2) && this.samePositionDimension(lng1, lng2);
  }

  samePositionDimension(value1, value2) {
    return Math.abs(value1 - value2) < 0.000001;
  }

  mapsIconsPath() {
    return this.props.company ? '/maps-icons-black' : '/maps-icons';
  }
}

class ProjectEventHandler {

  constructor() {
    this.subscribers = [];
  }

  fire(event) {
    for (var i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i](event);
    }
  }

  subscribe(subscriber) {
    this.subscribers.push(subscriber);
  }
}

export default ProjectMap;
