import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

// Connects to data-controller="googleplaces"
export default class extends Controller {
  static targets = [
    'input',
    'location_search',
    'street',
    'houseNo',
    'sublocalityLevel2',
    'sublocalityLevel1',
    'zip',
    'city',
    'administrativeAreaLevel1',
    'country',
    'latitude',
    'longitude'
  ]
  static values = {
    apikey: String,
    mock: Boolean,
    types: Array
  }

  connect() {
    if (this.mockValue) {
      this.streetTarget.value = 'mock street';
      this.houseNoTarget.value = '12';
      this.sublocalityLevel1Target.value = 'mock sublocalityLevel1';
      this.sublocalityLevel2Target.value = 'mock sublocalityLevel2';
      this.zipTarget.value = '12114';
      this.cityTarget.value = 'mock city';
      this.administrativeAreaLevel1Target.value = 'mock administrativeAreaLevel1';
      this.countryTarget.value = 'mock country';
      return;
    }

    if (this.apikeyValue == '') {
      return;
    }

    const loader = new Loader({
      apiKey: this.apikeyValue,
      version: 'weekly',
      // The loader sets the language by default to the preferred browser language. Since we persist
      // the the places information of projects and use it for searching by location, we need a
      // fixed language. Otherwise a project might have, say, the country "Germany" and won't be
      // found when searching for "Deutschland".
      language: 'de'
    });

    loader.importLibrary('places').then(function() {
      const options = {
        componentRestrictions: { country: ['de', 'ch', 'at'] },
        fields: ['address_components', 'geometry', 'formatted_address'],
      }

      if (this.hasTypesValue) {
        options['types'] = this.typesValue;
      }

      this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, options);

      this.autocomplete.addListener('place_changed', this.onPlaceChange.bind(this));
    }.bind(this));
  }
  
  onPlaceChange() {
    const place = this.autocomplete.getPlace();
    
    if (place.address_components === undefined) {
      return;
    }

    const componentTypeToTargets = {
      'sublocality_level_1': this.sublocalityLevel1Target,
      'sublocality_level_2': this.sublocalityLevel2Target,
      'postal_code': this.zipTarget,
      'locality': this.cityTarget,
      'administrative_area_level_1': this.administrativeAreaLevel1Target,
      'country': this.countryTarget
    }
    
    if (this.hasStreetTarget) {
      componentTypeToTargets['route'] = this.streetTarget;
      this.streetTarget.value = '';
    }

    if (this.hasHouseNoTarget) {
      componentTypeToTargets['street_number'] = this.houseNoTarget;
      this.houseNoTarget.value = '';
    }

    this.sublocalityLevel1Target.value = '';
    this.sublocalityLevel2Target.value = '';
    this.zipTarget.value = '';
    this.cityTarget.value = '';
    this.administrativeAreaLevel1Target.value = '';
    this.countryTarget.value = '';

    for (const component of place.address_components) {
      const componentType = component.types[0];
      const target = componentTypeToTargets[componentType];
      if (target !== undefined) {
        target.value = component.long_name;
      }
    }

    if (this.hasLatitudeTarget) {
      this.latitudeTarget.value = place.geometry.location.lat();
    }
    if (this.hasLongitudeTarget) {
      this.longitudeTarget.value = place.geometry.location.lng();
    }

    this.dispatch('changed');
  }

  selectFirstSuggestion(event) {
    if (document.querySelector('.pac-item')) {
      if (!document.querySelector('.pac-item-selected')) {
        event.target.dispatchEvent(
          new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          })
        );
      }

      event.preventDefault();
    }
  }
}
