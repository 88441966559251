import React from 'react';
import s from './style.module';
import AnimatePath from '../AnimatePath';
import cN from 'classnames';
import blubsch_cover from '!svg-inline-loader!./blubsch_solid_cover.svg';

class Testimonials extends React.Component {
    constructor(props) {
        super(props);
        this.testimonialCount = this.props.testimonials.length;
        this.decrement = this.decrement.bind(this);
        this.increment = this.increment.bind(this);
        this.state = { active: 0 };
    }
    render() {
        const { testimonials } = this.props;
        return (
            <div className={s.container}>
                <div className={s.inner}>
                    <Testimonial
                        testimonial={testimonials[this.state.active]}
                    />
                    {this.testimonialCount > 1 && (
                        <div>
                            {this.state.active !== 0 && (
                                <button className={s.left} onClick={this.decrement}>
                                    <AnimatePath duration={1} ease="ease" pathName="arrow" className={s.arrow} />
                                </button>
                            )}
                            {this.state.active !== this.testimonialCount - 1 && (
                                <button className={s.right} onClick={this.increment}>
                                    <AnimatePath duration={1} ease="ease" pathName="arrow" className={s.arrow} />
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
    increment() {
        this.setState({ active: (this.state.active + 1) % this.testimonialCount });
    }
    decrement() {
        this.setState({ active: (this.state.active + this.testimonialCount - 1) % this.testimonialCount });
    }
}

function Testimonial({ testimonial }) {
    return (
        <div className={s.testimonial}>
            <div className={s.imageContainer}>
                <img className={s.image} src={testimonial.image_url} />
                <div className={s.imageCover} dangerouslySetInnerHTML={{ __html: blubsch_cover }} />
            </div>
            <div className={s.body}>
                <p className={s.text}>
                    “{testimonial.text}”
                </p>
                <div className={s.author}>{testimonial.author}</div>
            </div>
        </div>
    );
}

export default Testimonials;
