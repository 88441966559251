import React from 'react';
import s from './style.module';
import { forEach } from 'lodash';

export default class FeedbackSelection extends React.Component {

  constructor(props) {
    super(props);
    this.inputPrefix = props.input_prefix;
    this.attributeName = props.attribute_name;
    this.type = props.type;
    this.test = props.test;
    this.collection = props.collection;
    this.selected = props.selected;
    this.onChange = this.onChange.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.state = { selected: props.selected, hovered: props.selected };
  }

  render() {
    var items = [];
    var selectionTitle = '';
    forEach(this.collection, (item) => {
      let title = item[0];
      let value = item[1];
      let name = this.inputPrefix + '[' + this.attributeName + ']';
      let id = this.inputPrefix + '_' + this.attributeName + '_' + value;
      switch (this.type) {
        case 'star':
          items.push(
            <span className={s.itemWrapper} key={value}>
              <input className={`${s.radio}${this.test ? ' ' + s.radioTest : ''}`} value={value} name={name} id={id} ref={id} type="radio" onChange={this.onChange} checked={this.state.selected == value} />
              <label className={s.label} htmlFor={id} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
              <i className={`glyphicon glyphicon-star component-feedback__star ${s.star}${(this.state.selected && this.state.selected >= value) || (this.state.hovered && this.state.hovered >= value) ? ' ' + s.active + ' component-feedback__active' : ''}`}></i>
              </label>
            </span>
          );
          if (this.state.hovered == value || (this.state.hovered == null && this.state.selected == value)) {
            selectionTitle = title;
          }
          break;
        case 'number':
          items.push(
            <span className={s.itemWrapper} key={value}>
              <input className={`${s.radio}${this.test ? ' ' + s.radioTest : ''}`} value={value} name={name} id={id} ref={id} type="radio" onChange={this.onChange} checked={this.state.selected == value} />
              <label htmlFor={id} className={`${s.label} ${s.number} component-feedback__number${this.state.selected == value ? ' ' + s.selected + ' component-feedback__selected' : ''}${this.state.hovered == value ? ' ' + s.hovered : ''}`} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
              {title}
              </label>
            </span>
          );
          break;
        case 'text':
          items.push(
            <span className={s.itemWrapper} key={value}>
              <input className={`${s.radio}${this.test ? ' ' + s.radioTest : ''}`} value={value} name={name} id={id} ref={id} type="radio" onChange={this.onChange} checked={this.state.selected == value} />
              <label htmlFor={id} className={`${s.label} ${s.text} component-feedback__text${this.state.selected == value ? ' ' + s.selected + ' component-feedback__selected' : ''}${this.state.hovered == value ? ' ' + s.hovered : ''}`} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
              {title}
              </label>
            </span>
          );
          break;
        default:
          throw `Unknown type '${this.type}'`;
      }
    });
    if ('star' == this.type) {
      return <div className={s.selectionWrapper}>{items} <span className={s.selectionTitle}>{selectionTitle}</span></div>;
    } else {
      return <div className={s.selectionWrapper}>{items}</div>;
    }
  }

  onChange(e) {
    this.setState({
      selected: e.currentTarget.value,
    });
  }

  onMouseOver(e) {
    var htmlFor = e.currentTarget.htmlFor;
    this.setState({
      hovered: this.refs[htmlFor].value,
    });
  }

  onMouseOut(e) {
    this.setState({
      hovered: this.state.selected,
    });
  }
}
